import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import AniLink from "gatsby-plugin-transition-link/AniLink"
// components
import Header from "../components/header/header";
// style
import '../styles/homePage.css'

// markup
const IndexPage = ({ data }) => (
  <main>
    <Helmet>
      <title>WebDevVision</title>
      <meta
        name="description"
        content="Nous sommes une agence de développement Web et Mobile sur Gaillac, passionnée et engagée dans la stratégie digitale, la conception UI / UX et le développement d’applications web et mobile."
      />
      <meta charSet="utf-8" />
      <html lang="fr" />
      <link rel="canonical" href="https://webdevvision.fr" />
    </Helmet>
    <Header haveLogo={false} haveBackground={false} />
    <div className="hp-container">
      <div className="hp-containerLeft">
        <h1 className="tertiary hp-title">
          Nous créons
          <br/>
          Des solutions
          <br/>
          Web et Mobile
        </h1>
        <section>
          <h2 className="tertiary hp-subtitle">
            Nous sommes une agence de développement Web et Mobile sur Gaillac,
            passionnée et engagée dans la stratégie digitale,
            la conception UI / UX et le développement d’applications web et mobile.
          </h2>
          <div className="hp-containerButton">
            <AniLink hex="#FF6E40" paintDrip className="hp-button" to="/agence/">
              Lancez-vous !
            </AniLink>
          </div>
        </section>
      </div>
      <div className="hp-containerRight">
        <div className="hp-containerImage">
          <Img
            fluid={data.file.childImageSharp.fluid}
            alt="Logo WebDevVision"
          />
        </div>
      </div>
      <div className="footer" >
        <ul className="container-link">
          <li>
            <a rel="noreferrer" target="_blank" href="https://twitter.com/GuillaumeSauss1?s=09">
              Twitter
            </a>
          </li>
          <li className="separator">|</li>
          <li>
            <a rel="noreferrer" target="_blank" href="https://www.linkedin.com/in/guillaume-saussol-7732a6145">
              LinkedIn
            </a>
          </li>
        </ul>
      </div>
    </div>
  </main>
)

export const query = graphql`
  query {
    file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IndexPage
